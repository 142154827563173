<template>
  <div :class="_class">
    <h3 v-if="showTitle && (Object.keys(_coverage_groups).length > 0 || slots.length > 0)" class="text-align-center margin-top-40 margin-bottom-40">
      {{ altTitle || "Tilvalgsforsikringer" }}
    </h3>
    <div v-else>
      &nbsp;
    </div>
    <div class="coverages">
      <slot />
      <div
        :class="coverageGroupClass(group)"
        v-for="(group, j) in _coverage_groups"
        :key="j"
      >
        <div v-for="(coverage, i) in group" :key="i" style="position: relative;">
          <div :class="{ coverage: true, blocked: 'blocked' in coverage.base && coverage.base.blocked }">
            <div v-if="'blocked' in coverage.base && coverage.base.blocked && 'blockedText' in coverage.base" class="block-text-padding"><i class="fa-regular fa-circle-info"></i>&nbsp;&nbsp;{{ coverage.base.blockedText }}</div>
            <div v-if="'blocked' in coverage.base && coverage.base.blocked" class="block-overlay"></div>
            <div class="coverage-item" :class="[{ disabled: !requirementsMet }]">
              <i
                class="fa-light fa-fw coverage-icon"
                :class="coverage.base.icon"
              ></i>
              <div class="coverage-text">
                <span class="coverage-name">{{ coverage.base.value }}</span>
                <span
                  class="coverage-description"
                  v-html="coverage.base.description"
                />
              </div>
              <FlipSwitch
                :value="coverage.base.chosen"
                @input="toggleCoverage(coverage)"
                :enabled="coverage.ext._enabled"
              />
            </div>
            <div v-for="key in coverage.base.keys" :key="`coverage-${key}`" style="border-top: 1px solid #e2e2e2;">
              <slot :name="`key-${key}`" v-bind:coverage="coverage" />
            </div>
          </div>
          <div v-if="'blocked' in coverage.base && coverage.base.blocked && 'blockedText' in coverage.base" class="block-text"><i class="fa-regular fa-circle-info"></i>&nbsp;&nbsp;{{ coverage.base.blockedText }}</div>
        </div>
      </div>
      <div class="coverage-group white" v-for="slot in slots" :key="slot">
        <div style="position: relative;">
          <div class="coverage"></div>
            <div style="border-top: 1px solid #e2e2e2;">
              <slot :name="slot" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FlipSwitch from "./FlipSwitch.vue";

export default {
  name: "coverages",
  components: {
    FlipSwitch,
  },
  props: {
    altTitle: {
      type: String,
      default: null,
    },
    slots: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: null,
    },
    requirementsMet: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "small",
    },
  },
  data: () => {
    return {
      coveragesExt: [],
      coveragesExtMap: new Map(),
    };
  },
  watch: {
    value: {
      handler() {
        this.handleChange();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    _coverage_groups() {
      return this.coveragesExt.reduce((prev, val) => {
        if (val.ext._parent === null) {
          if (val.base.chosen) {
            prev.push([val].concat(val.ext._children));
          } else {
            prev.push([val]);
          }
        }

        return prev;
      }, []);
    },
    _class() {
      return {
        container: true,
        small: this.size == "small",
      };
    },
  },
  methods: {
    toggleCoverage(coverage) {
      let r = Object.assign(this.value);

      // If switching off, reset children first to stop invalid price calc
      for (const child of coverage.ext._children) {
        if (child.ext._key in r) r[child.ext._key].chosen = false;
      }

      // Toggle value
      if (coverage.ext._key in r)
        r[coverage.ext._key].chosen = !r[coverage.ext._key].chosen;

      // Bump others?
      if ("bump" in coverage.base)
        for (const key of coverage.base.bump) r[key].chosen = false;

      // Update
      this.$emit("input", r);

      // Goto change
      if (!coverage.base.preventScroll) {
        this.scrollTo("packages");
      }
    },
    coverageGroupClass(group) {
      return {
        "coverage-group": true,
        chosen: group[0].base.chosen,
      };
    },

    /**
     * Function for handling change
     */
    handleChange() {
      // Check data integrity
      // 1. Switch all children off if parent is off
      let changed = false;
      let value = this.copy(this.value);

      for (const key in this.value) {
        const base = this.value[key];

        if (base.chosen && "parent" in base && !this.value[base.parent].chosen) {
          value[key].chosen = false;
          changed = true;
        }
      }

      // Changed?
      if (changed) {
        this.$emit("input", value);
        return;
      }

      // Handle
      const self = this;
      this.coveragesExt = [];
      this.coveragesExtMap.clear();

      // Build base data
      for (const key in this.value) {
        const base = this.value[key];

        const val = {
          base: base,
          ext: {
            _key: key,
            _parent: this.value[key].parent || null,
            _children: [],
            _enabled: "disabled" in base ? !base.disabled : true,
          },
        };

        this.coveragesExt.push(val);
        this.coveragesExtMap.set(key, val);
      }

      // Add _children and calc _enabled
      this.coveragesExtMap.forEach((val) => {
        if (
          "parent" in val.base &&
          self.coveragesExtMap.has(val.base.parent)
        ) {
          self.coveragesExtMap.get(val.base.parent).ext._children.push(val);
        }

        self.coveragesExtMap.get(val.ext._key).ext._enabled && self.coveragesExt.reduce((prev, val2) => { return ('exclude' in val2.base ? !(val2.base.exclude.includes(val.ext._key) && val2.base.chosen) : true) && prev; }, true);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.coverage-group {
  &.white {
    background-color: white;
  }
}

.coverage {
  &.blocked {
    opacity: 0.5;
  }
}

.block-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.block-text, .block-text-padding {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  line-height: 24px;
  color: white;
  z-index: 1001;
  background-color: #3f3666;
  font-size: 14px;
  padding: 8px 8px 8px 20px;
}

.block-text-padding {
  position: relative;
  opacity: 0;
}

</style>
